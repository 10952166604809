import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';

const themeAdapter = createEntityAdapter();
const initialState = themeAdapter.getInitialState({
    theme: 'light'
});

const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        setTheme: (state, action) => {
            state.theme = action.payload;
        },
        toggleTheme: (state) => {
            const newTheme = state.theme === 'light' ? 'dark' : 'light'
            state.theme = newTheme;
        }
    }
});

const {actions, reducer} = searchSlice;

export default reducer;

// export const {selectAll} = themeAdapter.getSelectors(state => state.search);

export const {
    setTheme,
    toggleTheme
} = actions;